import React, { FC, useState, useCallback, useMemo, useEffect } from 'react'

import { FormikProvider } from 'formik'
import styled from 'styled-components/native'
import { ActivityIndicator } from 'react-native'
// Hooks
import useTranslation from '../../../../../../hooks/useTranslation'
import useDynamicForm from '../../../../../../hooks/useDynamicForm'
import useCurrentUser from '../../../../../../hooks/useCurrentUser'
import useIsSmallScreen from '../../../../../../hooks/useIsSmallScreen'
import useSubmissionAverageMutation from '../../../../hooks/useSubmissionAverageMutation'
import usePreventUnsavedChanges from '../../../../../../hooks/unsavedAdvisorChanges/usePreventUnsavedChanges'
import useUnsavedAdvisorChanges from '../../../../../../hooks/unsavedAdvisorChanges/useUnsavedAdvisorChanges'
// Components
import { Flex } from '../../../../../../components/FlexBox'
import Button from '../../../../../../ui-library/Button'
import {
  AnswerEntityTypeEnum,
  Question,
  QuestionTypeEnum,
  ValidationPhaseEnum
} from '../../../../../../types/form'
// Types
import {
  CallForSubmission,
  OriginSourceEnum,
  SubmissionReview
} from '../../../../../../types'

// Styled components
const ButtonContainer = styled(Flex).attrs(({ isSmallScreen }) => ({
  w: '100%',
  flexWrap: 'wrap',
  position: 'sticky',
  flexDirection: 'row',
  alignItems: isSmallScreen ? 'flex-end' : 'flex-start'
}))`
  bottom: 0px;
  ${({ theme: { colors } }) => `
    background-color: ${colors.appBackground};
  `}
`

interface RatingsProps {
  callForSubmission: CallForSubmission
  reviewFormId?: string
  isReadOnly: boolean
  submissionReview: SubmissionReview
  onDirty?: (dirty: boolean) => any
}

const getFavoriteQuestion = (reviewQuestions): Question | undefined => {
  return reviewQuestions?.find(
    question =>
      question.type === QuestionTypeEnum.Checkbox &&
      question.questionText?.toLowerCase().includes('favorite')
  )
}

const Ratings: FC<RatingsProps> = ({
  callForSubmission,
  reviewFormId,
  submissionReview,
  isReadOnly,
  onDirty
}) => {
  const { t } = useTranslation()
  const isSmallScreen = useIsSmallScreen()
  const { currentUserId } = useCurrentUser()
  const { updateAverage } = useSubmissionAverageMutation(callForSubmission?.id)
  const favQuestion = getFavoriteQuestion(
    callForSubmission?.reviewFormQuestions
  )

  const advisorFav = useMemo(() => {
    return callForSubmission?.advisorsFavCount?.find(
      ac => ac.id === currentUserId
    )
  }, [callForSubmission])

  const initialFavCount = useMemo(() => advisorFav?.favCount ?? 0, [
    advisorFav?.favCount
  ])
  const [advisorFavCount, setAdvisorFavCount] = useState(initialFavCount)

  useEffect(() => {
    setAdvisorFavCount(advisorFav?.favCount ?? 0)
  }, [initialFavCount])

  const submissionBelongsToTheFavorites: boolean = useMemo(
    () =>
      advisorFav?.favAnswers?.length && submissionReview?.formAnswerId
        ? advisorFav?.favAnswers?.some(
            formAnswer =>
              formAnswer.formSubmissionId === submissionReview?.formAnswerId
          )
        : false,
    [advisorFav, submissionReview?.formAnswerId]
  )

  const shouldDisableFavQuestion = useCallback(() => {
    return !submissionBelongsToTheFavorites
      ? advisorFavCount >= callForSubmission.advisorMaxFav
      : false
  }, [
    callForSubmission?.advisorMaxFav,
    advisorFavCount,
    submissionBelongsToTheFavorites
  ])

  const { resetState } = useUnsavedAdvisorChanges()

  const updateScore = async (_, formData) => {
    if (formData) {
      await updateAverage({
        variables: {
          submissionId: submissionReview?.submissionId
        }
      })
      resetState()
    }
  }

  const onCheckboxChange = useCallback(
    ({ initialValue, newValue }) => {
      let newFavCount

      if (initialValue === newValue) {
        newFavCount = initialFavCount
      } else {
        if (newValue && !initialValue) {
          newFavCount = initialFavCount + 1
        } else {
          newFavCount = initialFavCount - 1
        }
      }

      setAdvisorFavCount(newFavCount)
    },
    [initialFavCount, advisorFavCount]
  )

  const {
    form,
    loadingFormData,
    component: dynamicForm,
    helpers
  } = useDynamicForm({
    isReadOnly,
    formId: reviewFormId,
    formSubmissionId: submissionReview?.formAnswerId,
    formPhase: ValidationPhaseEnum.Review,
    answerEntityType: AnswerEntityTypeEnum.InnovationReview,
    originSource: OriginSourceEnum.Innovation,
    onSubmit: updateScore,
    onDirty,
    callForSubmission,
    beforeRemoveDisabled: true,
    ...(favQuestion
      ? {
          formQuestionConditions: {
            [favQuestion.id]: {
              isDisabled: shouldDisableFavQuestion,
              customMsg: `${advisorFavCount}/${callForSubmission?.advisorMaxFav}`,
              onChange: onCheckboxChange
            }
          }
        }
      : {})
  })

  usePreventUnsavedChanges(form, isReadOnly, loadingFormData)

  const [isSubmitting, setIsSubmitting] = useState(false)

  if (loadingFormData) {
    return null
  }
  const validateForm = async () => {
    const errors = await form.validateForm()
    helpers.setAllFieldsTouched()

    return helpers.getFormErrorCount(errors)
  }

  const submitReview = async () => {
    setIsSubmitting(true)

    await new Promise<void>(resolve =>
      setTimeout(async () => {
        const errors = await validateForm()

        if (!errors) {
          await form.submitForm()
        }
        onDirty?.(false)
        setIsSubmitting(false)
        resolve()
      }, 10)
    )
  }

  return (
    <Flex flexDirection="column" justifyContent="flex-start" width="100%">
      <FormikProvider value={form}>
        <Flex
          style={{
            position: 'relative'
          }}
        >
          {isSubmitting ? (
            <ActivityIndicator
              style={{
                alignItems: 'baseline',
                position: 'absolute',
                top: '50%',
                right: '55%'
              }}
              size="large"
            />
          ) : null}
          <Flex
            flexDirection="column"
            style={{
              ...(isSubmitting
                ? {
                    filter: 'blur(4px)'
                  }
                : {})
            }}
          >
            {dynamicForm}
          </Flex>
          <ButtonContainer isSmallScreen={isSmallScreen}>
            <Button
              type="solid"
              title={t('curation:buttons:submit')}
              buttonStyle={{ width: '100%' }}
              containerStyle={{
                width: '178px',
                marginBottom: 0
              }}
              isProcessing={isSubmitting}
              disabled={!form.dirty}
              onPress={submitReview}
              accessibilityLabel={'endOfScreen'}
            />
          </ButtonContainer>
        </Flex>
      </FormikProvider>
    </Flex>
  )
}

export default Ratings
