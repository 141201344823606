import React, { FC } from 'react'
import { ViewStyle, Switch } from 'react-native'
import { useTheme } from 'styled-components/native'
// Hooks
import useTranslation from '../../../hooks/useTranslation'
// Components
import Modal from '../../../components/common/Modal'
import { Text } from '../../../components/common/Text'
import TextInput from '../../../ui-library/TextInput'

import Label from '../../../ui-library/TextInput/Label'
import MarketSegmentSelector from './MarketSegmentSelector'
import { ErrorText } from '../../authentication/components/SharedStyledComponents'
// Types
import { DropdownValue } from '../../../components/common/Dropdown'
import {
  CategoryMngModalProps,
  CategoryMngModalState
} from '../hooks/useCategoryMngController'
import {
  CancelButton,
  Footer,
  FooterLegend,
  InputContainer,
  ModalContainer,
  SaveButton,
  Title
} from '../constants'

// Types
interface CategoryModalProps extends CategoryMngModalProps {}

const CategoryModal: FC<CategoryModalProps> = ({
  isOpen,
  formik,
  isLoading,
  modalState,
  onClose,
  onDeleteCategory
}) => {
  // Hooks
  const { colors } = useTheme()
  const { t } = useTranslation()
  const { values, errors, dirty, isValid, touched } = formik

  // Events
  const validateAndSubmit = () => {
    formik.validateForm().then(() => formik.submitForm())
  }

  // Render
  const dpStyle: ViewStyle = {
    minHeight: 40,
    backgroundColor: colors.inputBackground
  }

  const isEdit = !!values?.id && modalState === CategoryMngModalState.update
  const enableSubmit = isValid && dirty && !isLoading

  return (
    <Modal close={onClose} open={isOpen}>
      <Title>
        {t(
          `platformManagement:categories:${
            isEdit ? 'editCategory' : 'addCategory'
          }`
        )}
      </Title>
      <ModalContainer>
        <InputContainer>
          <TextInput
            value={values.name}
            onChangeText={value => {
              formik.setFieldValue('name', value)
            }}
            label={t('platformManagement:categories:columnName')}
          />
          {errors.name && touched.name ? (
            <ErrorText>{errors.name}</ErrorText>
          ) : null}
        </InputContainer>
        <InputContainer>
          <Label
            label={t('platformManagement:marketSegment:addCategoryPlaceholder')}
          />
          <MarketSegmentSelector
            placeholder=""
            dropdownStyle={dpStyle}
            value={values.marketSegmentIds as DropdownValue}
            onSelect={(selection: any) => {
              formik.setFieldValue('marketSegmentIds', selection)
            }}
          />
          {errors.marketSegmentIds && touched.marketSegmentIds ? (
            <ErrorText>{errors.marketSegmentIds}</ErrorText>
          ) : null}
        </InputContainer>
        <InputContainer>
          <Label label={t('platformManagement:categories:isActive')} />
          <Switch
            value={values.isActive}
            onValueChange={newValue => {
              formik.setFieldValue('isActive', newValue)
            }}
            style={{
              // margin: space[3],
              transform: [{ scaleX: 0.8 }, { scaleY: 0.8 }]
            }}
            trackColor={{
              true: colors.primaryPalette.accent,
              false: colors.border
            }}
          />
        </InputContainer>
      </ModalContainer>
      <Footer>
        <CancelButton
          isProcessing={isLoading}
          onPress={onClose}
          title={t('common:buttons:cancel')}
        />
        <SaveButton
          disabled={!enableSubmit}
          isProcessing={isLoading}
          onPress={validateAndSubmit}
          title={t(`common:buttons:${CategoryMngModalState[modalState]}`)}
        />
        {values.id ? (
          <SaveButton
            disabled={!!values.submissionsCount}
            onPress={() => {
              onDeleteCategory(values.id ?? undefined)
            }}
            isDelete
            isProcessing={isLoading}
            title={t(`common:buttons:delete`)}
          />
        ) : null}
      </Footer>
      {!!values.submissionsCount && (
        <FooterLegend>
          <Text>{t('platformManagement:categories:cantDeleteLegend')}</Text>
        </FooterLegend>
      )}
    </Modal>
  )
}

export default CategoryModal
