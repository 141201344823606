import { FormTypeEnum } from './screens/PlatformManagement/FormsManagement/types'
import { EmployeeCountRangeEnum } from './types/company'
import { JSONObject, Question, QuestionTypeEnum } from './types/form'

export type RootStackParamList = {
  App: undefined
  Login: undefined
}

export type DateTime = Date | string

export enum InnovationAccessKey {
  ADVISE = 'advise',
  ASSIGN_ADVISORS = 'advisorAssignment',
  CFS_SETTINGS = 'callForSubmissionSettings',
  CFS_ADVISING_SETTINGS = 'callForSubmissionAdvisingSettings',
  CURATE = 'curate',
  DIRECTORY = 'directory',
  INNOVATOR_TEAM = 'innovatorTeam',
  PLATFORM_MANAGEMENT = 'platformManagement',
  CFS_TESTING = 'callForSubmissionTesting'
}

export type InnovationAccess = Record<InnovationAccessKey, boolean>

export type AppStackParamList = {
  ProductList: undefined
  ProductGeneral: undefined
  NotFound: undefined
  ComingSoon: undefined
}

export enum RoleEnum {
  Admin = 'admin',
  Associate = 'associate',
  Member = 'member',
  Developer = 'developer',
  InnovatorTeamMember = 'innovatorTeamMember',
  InnovatorTeamAdmin = 'innovatorTeamAdmin',
  InnovationMember = 'innovationMember',
  InnovationAssociate = 'innovationAssociate',
  InnovationAdvisor = 'innovationAdvisor',
  InnovationInvestor = 'innovationInvestor',
  InnovationStaff = 'innovationStaff',
  InnovationAdmin = 'innovationAdmin'
}

export interface Origin {
  id: string
  source: OriginSourceEnum
  trusted: boolean
}

export interface FileAttachment {
  id: string
  cloudinaryId: string
  filename?: string | null
  moderationStatus?: string
}

export interface MarketSegment {
  id: string
  name: string
}

export interface QuestionOptions {
  value: string
  name: string
}

export interface Country {
  id: string
  name: string
}

export interface Domain {
  name: string
}

export interface CompanyProfile {
  id: string
  url: string
  domains: Domain[]
  country: Country
  yearFounded: string
  funding: number
  employeeCount: string
  delegationInterest?: Delegation[]
}

export interface InnovatorAlumniMembership {
  id: string
}

export interface Company {
  alumniMembership: InnovatorAlumniMembership
  isAlumni: boolean
  id: string
  name?: string
  products?: InnovatorProduct[]
  companyProfile?: CompanyProfile
  innovatorMembers: Person[]
  canEdit?: boolean
  canAddInnovatorProducts?: boolean
}

export interface InnovatorProductPublishLog {
  id: string
  innovatorProductId: string
  isPublished: boolean
  publishedBy?: any
  publishedAt?: any
}

export interface InnovatorProduct {
  id: string
  companyId: string
  submissionId: string
  updatedAt: Date
  submission: InnovationSubmission
  formId: string
  hasLatestPDF: boolean
  delegation?: Delegation
  canDelete?: boolean
  canEdit?: boolean
  canPublish?: boolean
  publishedLog?: InnovatorProductPublishLog
}

export interface Person {
  id: string
  firstName: string
  lastName: string
  jobTitle: string
  photo?: {
    id: string
    cloudinaryId: string
  }
  innovationCompany: Company
  salesforceId?: string
  primaryGroup?: {
    id: string
    name: string
  }
  emailAddresses?: { email: string }[]
  companies?: Company[]
  user: User
}

export interface CurrentUser {
  id: string
  role: RoleEnum
  roles: RoleEnum[]
  person: Person
  innovationAccess: InnovationAccess
}

export interface User {
  id: string
  role: RoleEnum
  roles: RoleEnum[]
  person: Person
  settings: Settings
  innovationOnboardedAt?: string
  lastLogin?: string
  submissionsCount: number
  cfsSubmissionReviews: SubmissionReview[]
}

export enum OriginSourceEnum {
  Salesforce = 'salesforce',
  Innovation = 'innovation',
  Seed = 'seed',
  Api = 'api',
  Migration = 'migration',
  Ehir = 'ehir',
  Zoom = 'zoom'
}

export enum InnovationSubmissionStatusEnum {
  inProgress = 'inProgress',
  submitted = 'submitted',
  rejected = 'rejected',
  academy = 'academy',
  flagged = 'flagged',
  fastPassed = 'fastPassed'
}

export interface Group {
  id: string
  name: string
  shortName: string
}

export interface Cohort {
  group: Group
  isConfirmed: boolean
}

export interface Form {
  id: string
  formType: string
  type: FormTypeEnum
  name: string
  questionGroupId?: string
  isActive: boolean
}

export interface Delegation {
  id: string
  name: string
  description?: string
  groups: Group[]
  marketSegment?: MarketSegment
  forms?: Form[]
}

export interface StepDateRange {
  id: string
  callForSubmissionId: string
  curationStep: CallForSubmissionStepEnum
  openAt: DateTime
  closeAt: DateTime
}

export interface AdvisorsInfo {
  user: User
  status: number
  reviews: number
  favorite: number
  academy: number
}

export interface GroupSelection {
  group: Group
  isConfirmed: Boolean
  deletedById?: string
  hasPresentedBefore: boolean
}

export interface CallForSubmission {
  id: string
  name: string
  status: CallForSubmissionStatusEnum
  currentStep: CurrentCFSStep
  stepDateRanges: StepDateRange[]
  delegations: Delegation[]
  marketSegments: MarketSegment[]
  formId: string
  innovationSubmissions: InnovationSubmission[]
  reviewFormId?: string
  reviewFormQuestions?: Question[]
  cutFormId?: string
  advisorsUsers?: any[]
  academyElegible?: boolean
  shortlistLimit: number
  favoritesLimit: number
  advisorMaxFav: number
  advisorsFavCount?: AdvisorFavCount[]
  advisorsInfo?: AdvisorsInfo[]
  totalSubmissions?: number
  canEditSubmissions?: boolean
}
interface AdvisorFavCount {
  id: string
  favCount: number | null
  favAnswers: FavAnswer[]
}

export interface FavAnswer {
  id: string
  formSubmissionId: string
}

export interface AdvisorInfo {
  id: string
  userInfo: User
  totalAdvised: number
  submissionsToReview: SubmissionReview[]
}

export interface SubmissionInfo {
  name: string
  category: string
  categoryLabel: string
}

export interface AdvisorReview {
  answers: Answer[]
  advisorId: string
}

export interface InnovationCategory {
  id: string
  name: string
  sort: number
  isActive: boolean
  marketSegments: MarketSegment[]
}

export interface InnovationSubmission {
  advisorsReviews: AdvisorReview[]
  id: string
  legacyOriginId?: string | null
  submissionInfo?: SubmissionInfo
  callForSubmissionId: string
  callForSubmission?: CallForSubmission
  step: CallForSubmissionStepEnum
  status: InnovationSubmissionStatusEnum
  cohorts: Cohort[]
  cohortsHistory: Group[]
  submissionReview?: SubmissionReview
  submissionReviews?: SubmissionReview[]
  submissionAverage?: SubmissionAverage
  updatedAt: DateTime
  submittedAt: DateTime
  hasBeenSubmitted: boolean
  hasBeenFastPassed: boolean
  pdfCloudinaryId?: string
  company: Company
  canEdit?: boolean
  canSubmit?: boolean
  canDelete?: boolean
  canSubmitAdvisorFeedback: boolean
}

export enum CallForSubmissionStatusEnum {
  upcoming = 'upcoming',
  open = 'open',
  closed = 'closed'
}

export enum CallForSubmissionStepEnum {
  submission = 'submission',
  preCurate = 'preCurate',
  advising = 'advising',
  cohort = 'cohort',
  voting = 'voting',
  finalSelections = 'finalSelections',
  selection = 'selection',
  event = 'event'
}

export enum CallForSubmissionStepStatusEnum {
  upcoming = 'upcoming',
  open = 'open',
  closed = 'closed'
}

export interface CurrentCFSStep {
  name: CallForSubmissionStepEnum | null
  status: CallForSubmissionStepStatusEnum
  openAt: DateTime | null
  closeAt: DateTime | null
}

export interface AnswerFileAttachmentMap {
  id: string
  name: string
  answerId: string
  fileAttachment: FileAttachment
  origin: Origin
}

export interface Answer {
  id: string
  questionId: string
  answerEntityId?: string
  answerEntityType?: string
  value?: string
  formSubmissionId?: string
  fileAttachments?: AnswerFileAttachmentMap[]
}

export interface FormAnswer {
  id: string
  submissionEntityId: string
  submissionEntityType: string
}

export interface SubmissionReview {
  id: string
  userId: string
  submissionId: string
  avgScore: number | null
  formAnswerId: string
}

export interface AnswerContent {
  content: string
  author: string
}

export interface SubmissionReviewQuestionAverage {
  questionId: string
  questionTemplateId: string
  questionText: string
  questionType: QuestionTypeEnum
  questionWeight: number
  answers: [Answer]
  answerScore: number | null
  answerContents?: AnswerContent[] | null
}

export interface SubmissionScore {
  id: string
  submissionId: string
  questionId: string
  question: Question
  value: number | null
}

export interface SubmissionAverage {
  id: string
  submissionId: string
  wass: number | null
  totalFav?: number | null
  submissionScores: SubmissionScore[]
  avgScore: number | null
  stdDev: number | null
  totalAcademy: number | null
  configData?: JSONObject
}

export interface CompanyProfileValues {
  name: string
  url: string
  yearFounded: string
  funding: string
  employeeCount: EmployeeCountRangeEnum
  domains: string[]
  delegationInterest?: string[] | null
  countryId: string
}

export enum TeamMemberRolesEnum {
  InnovatorTeamAdmin = RoleEnum.InnovatorTeamAdmin,
  InnovatorTeamMember = RoleEnum.InnovatorTeamMember
}

export const TEAM_MEMBER_ROLES = [
  RoleEnum.InnovatorTeamAdmin,
  RoleEnum.InnovatorTeamMember
]

export enum SubmissionVotingVoteEnum {
  Shortlisted = 'shortlisted',
  NotInterested = 'notInterested'
}

export interface Settings {
  innovatorCommsPlatformAdmin: boolean
  innovatorCommsCFS: boolean
  innovatorCommsCFSSelectionAndPresentation: boolean
  innovatorCommsInvoicing: boolean
}

export interface UpsertInnovationCategoryInput {
  id?: string
  name: string
  isActive: boolean
  marketSegmentIds?: string[]
  originSource?: OriginSourceEnum
}
