import React, { FC } from 'react'
import {
  getFocusedRouteNameFromRoute,
  useRoute
} from '@react-navigation/native'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'

import GeneralSettings from './GeneralSettings'
import useMixpanel, { EVENT_MAP } from '../../../hooks/useMixpanel'
import useHistoryRoutes from '../../../hooks/useHistoryRoutes'
import useCallForSubmissionQuery from '../../Curation/hooks/useCallForSubmissionQuery'
import useIsSmallScreen from '../../../hooks/useIsSmallScreen'
import {
  ScreenContainerWithMenuHeader,
  ShadowContainerWithTabs
} from '../../../components/layout/ScreenContainer'

import TopTabBar from '../../../components/layout/TopTabBar'
import TopTabBarMobile from '../../../components/layout/TopTabBarMobile'
import HeaderNav from './components/HeaderNav'
import AdvisorSettings from './AdvisorSettings'
import TestingSettings from './TestingSettings'
import useInnovationAccess from '../../../hooks/useInnovationAccess'

const Tabs = createMaterialTopTabNavigator()

interface SettingsTabsProps {
  callForSubmissionId: string
}

const GENERAL_SETTINGS_TAB = {
  name: 'GeneralSettings',
  Component: GeneralSettings,
  options: {
    title: `General Settings`,
    webTitle: 'General Configuration',
    disabled: false
  }
}

const ADVISING_SETTINGS_TAB = {
  name: 'AdvisingSettings',
  Component: AdvisorSettings,
  options: {
    title: `Advising Settings`,
    webTitle: 'Advising Configuration',
    disabled: false
  }
}

const TESTING_SETTINGS_TAB = {
  name: 'TestingSettings',
  Component: TestingSettings,
  options: {
    title: `Testing Settings`,
    webTitle: 'Testing Configuration',
    disabled: false
  }
}

const SettingsTabs: FC<SettingsTabsProps> = ({ callForSubmissionId }) => {
  const { trackWithProperties } = useMixpanel()

  const route: any = useRoute()

  const { history, pushToHistory } = useHistoryRoutes()
  const { callForSubmission, loading: isFetching } = useCallForSubmissionQuery(
    callForSubmissionId,
    false,
    false,
    false,
    {},
    true
  )
  const currentRouteName = getFocusedRouteNameFromRoute(route)

  // During deepLink in any of the curation tabs we don't have anything in the history
  // to go back to after going into the details page. So we push the current tab
  if (!history.length) {
    pushToHistory({
      screen: currentRouteName
    })
  }

  const {
    advisorAssignment,
    callForSubmissionTesting,
    callForSubmissionSettings,
    callForSubmissionAdvisingSettings
  } = useInnovationAccess()

  const isSmallScreen = useIsSmallScreen()
  const tabScreens: any[] = []

  if (callForSubmissionSettings) {
    tabScreens.push(GENERAL_SETTINGS_TAB)
  }

  if (callForSubmissionAdvisingSettings || advisorAssignment) {
    tabScreens.push(ADVISING_SETTINGS_TAB)
  }

  if (callForSubmissionTesting) {
    // Add the Testing Feature Flag
    tabScreens.push(TESTING_SETTINGS_TAB)
  }

  if (isFetching || !callForSubmission) {
    return null
  }

  // Bug relating to browser resize and tabs not centering - https://github.com/react-navigation/react-navigation/issues/8837
  return (
    <ScreenContainerWithMenuHeader screenCategory="settings">
      <ShadowContainerWithTabs>
        <HeaderNav callForSubmission={callForSubmission} />
        <Tabs.Navigator
          swipeEnabled={false}
          tabBar={props =>
            !isSmallScreen ? (
              <TopTabBar {...props} />
            ) : (
              <TopTabBarMobile {...props} />
            )
          }
        >
          {tabScreens.map(({ name, Component, options }) => (
            <Tabs.Screen
              key={name}
              name={name}
              children={() => (
                <Component
                  isLoading={isFetching}
                  callForSubmission={callForSubmission}
                />
              )}
              options={options}
              listeners={{
                tabPress: () => {
                  trackWithProperties(EVENT_MAP.click.tab, {
                    tabName: name
                  })
                }
              }}
            />
          ))}
        </Tabs.Navigator>
      </ShadowContainerWithTabs>
    </ScreenContainerWithMenuHeader>
  )
}

export default SettingsTabs
