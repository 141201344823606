import { ImageStyle, StyleProp, TextStyle, ViewStyle } from 'react-native'
import { CallForSubmission, MarketSegment, OriginSourceEnum } from '../../types'

export type JSONValue = string | number | boolean | JSONObject | JSONArray

type StringOptional = string | null | undefined
type DateOptional = Date | null | undefined

export type JSONArray = [JSONValue]

export interface JSONObject {
  [x: string]: JSONValue
}

export interface GenericStyles {
  [key: string]:
    | StyleProp<ViewStyle>
    | StyleProp<TextStyle>
    | StyleProp<ImageStyle>
    | string
    | number
    | boolean
}

export interface JSONStyles extends GenericStyles {
  renderCounter?: boolean
  textareaLineHeight?: number
  containerStyles?: StyleProp<ViewStyle>
  errorContainerStyles?: StyleProp<ViewStyle>
  labelStyles?: StyleProp<TextStyle>
  buttonStyles?: StyleProp<ViewStyle>
  imageStyles?: StyleProp<ImageStyle>
  styles?: StyleProp<ViewStyle> | StyleProp<TextStyle> | StyleProp<ImageStyle>
  checkedIconName?: string
  questionWeight?: number
}

export interface Tracking {
  createdAt: DateOptional
  createdById: StringOptional
  updatedAt: DateOptional
  updatedById: StringOptional
  deletedAt: DateOptional
  deletedById: StringOptional
  originId: StringOptional
}

export enum QuestionTypeEnum {
  TextInput = 'textInput',
  Textarea = 'textarea',
  Integer = 'integer',
  Checkbox = 'checkbox',
  Dropdown = 'dropdown',
  FileDropzone = 'fileDropzone',
  Rating = 'rating',
  DateInput = 'dateInput',
  MultiSelect = 'multiSelect'
}

export enum QuestionGroupTypeEnum {
  Form = 'form',
  Tab = 'tab',
  Block = 'block',
  DynamicBlock = 'blockDynamic'
}

export enum ValidationTypeEnum {
  required = 'required',
  notRequired = 'notRequired',
  nullable = 'nullable',
  min = 'min',
  max = 'max',
  matches = 'matches',
  email = 'email',
  phone = 'phone'
}

export enum ValidationPhaseEnum {
  All = 'all',
  Save = 'save',
  Submit = 'submit',
  Review = 'review',
  Publish = 'publish'
}

export interface Validation {
  id: string
  type: ValidationTypeEnum
}

export interface Answer {
  id?: string
  value: any
  answerEntityId?: string
  answerEntityType?: string
  formSubmissionId?: string
  questionId: string
  question?: Question
}

export interface QuestionValidationMap {
  id: string
  questionId: string
  errorMessage?: string
  phase?: ValidationPhaseEnum
  value: any
  validation: Validation
  type?: ValidationTypeEnum
}

export interface Question {
  id: string
  parentId?: string
  parent?: Question
  questionText: string
  type: QuestionTypeEnum
  subType?: string
  style: JSONStyles
  configData?: JSONObject
  dataEntityId?: string
  dataEntityType: string
  optionsEntity?: string
  optionsValues?: any
  answer: Answer
  questionValidationMaps: QuestionValidationMap[]
  questionDependencies: QuestionDependency[]
  isDynamicQuestion?: boolean
  systemLabel?: string
  __typename: string
}

export interface QuestionGroupEntityMap {
  id: string
  parentId?: string
  parent?: QuestionGroupEntityMap
  questionGroupId: string
  entityType: EntityTypeEnum
  entityId: string
  entity: Entities
  sequence: number
  __typename: string
}

export interface QuestionGroupConfigData {
  title?: string
  capitalized?: boolean
  titleStyle?: any
  description?: string
  questionGroupTitle?: string
}

export interface QuestionGroup {
  id: string
  parentId?: string
  parent?: QuestionGroup
  dynamic?: boolean | string
  type: QuestionGroupTypeEnum
  style: JSONStyles
  questionGroupEntityMaps: QuestionGroupEntityMap[]
  configData: QuestionGroupConfigData
  __typename: string
}

export type Entities = Question | QuestionGroup

export enum EntityTypeEnum {
  Question = 'question',
  QuestionGroup = 'question_group'
}

export interface FormData {
  id: string
  name: string
  questions: Question[]
  questionGroups: QuestionGroup[]
  formGroup: QuestionGroup
}

export enum AnswerEntityTypeEnum {
  InnovationSubmission = 'InnovationSubmission',
  InnovationProduct = 'InnovationProduct',
  InnovationReview = 'InnovationReview'
}

export enum QuestionDependencyTypeEnum {
  ParentChild = 'parentChild'
}

export enum QuestionDependencyEntityEnum {
  InnovationCategory = 'InnovationCategory'
}

export interface QuestionDependencyValue {
  entity?: QuestionDependencyEntityEnum
  entityAnswer?: string
  entityAnswerNotSelected?: boolean
  removedValues?: string[]
  shouldHide: boolean
}

export interface QuestionDependency extends Tracking {
  id: string
  question1Id: string
  question1?: Question
  question2Id: string
  question2?: Question
  type: QuestionDependencyTypeEnum
  value: QuestionDependencyValue | null
}

export interface FormSubmissionMetaData {
  type?: string
  formId?: string
  formSubmissionId?: string
  innovationSubmissionId?: string
  answerEntityId?: string
  answerEntityType?: string
  originSource: OriginSourceEnum
  callForSubmission?: CallForSubmission
}
export interface MarketSegmentsMetaData {
  marketSegments?: MarketSegment[] | null
}
