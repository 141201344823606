import React, { FC, useMemo } from 'react'
import { Flex } from '../../../components/FlexBox'
import { Title } from './SharedComponents'
import { Text } from '../../../components/common/Text'
import DonutChart from './DonutChart'
import { InnovationSubmission } from '../../../types'
import useTranslation from '../../../hooks/useTranslation'
import styled, { useTheme } from 'styled-components/native'

const MAX_SCORE = 5

const Container = styled(Flex)`
  ${({ theme: { radii, colors } }) => `
  border-radius: ${radii[4]}px;
  border: 1px solid ${colors.tints.grays.g100};
  padding: 0;
  background: ${colors.appBackground};
  overflow: hidden;
  `}
`
const SubmissionSummary = styled(Flex)`
  ${({ theme: { space, colors } }) => `
  border: 0px solid ${colors.tints.grays.g100};
  border-top-width: 1px;
  padding: ${space[4]}px;
  background: ${colors.appBackground};
  `}
`

const DonutContainer = styled.View`
  max-width: 320px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const LightLabel = styled.Text`
  ${({ theme: { colors, fontWeights, fontSizes } }) => `
    color: ${colors.tints.grays.g400};
    font-weight: ${fontWeights.regular};
    font-size: ${fontSizes[3]}px;
  `}
`

const BoldLabel = styled.Text`
  ${({ theme: { sizes, fontWeights, fontSizes } }) => `
    font-weight: ${fontWeights.bold};
    margin-top: ${sizes[1]}px;
    font-size: ${fontSizes[6]}px;
  `}
`

interface SubmissionStatusProps {
  submission?: InnovationSubmission
}
const SubmissionStatus: FC<SubmissionStatusProps> = ({ submission }) => {
  const { t } = useTranslation()
  const { colors, space } = useTheme()

  const scorePercentage = useMemo(() => {
    let avg = 0
    if (submission?.submissionAverage?.wass) {
      avg = submission?.submissionAverage?.wass
    }
    return (avg * 100) / MAX_SCORE
  }, [submission?.submissionAverage?.wass])

  const callForSubmission = submission?.callForSubmission
  const marketSegment =
    callForSubmission &&
    callForSubmission.marketSegments &&
    callForSubmission.marketSegments.length > 0
      ? callForSubmission?.marketSegments[0]?.name
      : ''

  return (
    <Container>
      <Flex
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Flex
          style={{
            maxWidth: '50%',
            minHeight: 120,
            padding: space[4]
          }}
        >
          <Title>{submission?.submissionInfo?.name}</Title>
          {callForSubmission ? (
            <Text>{callForSubmission?.name || ''}</Text>
          ) : null}
          {marketSegment ? (
            <Text
              styles={{
                marginTop: space[2]
              }}
            >
              {marketSegment || ''}
            </Text>
          ) : null}
        </Flex>
        <DonutContainer>
          <DonutChart
            color={colors.primaryPalette.accent}
            contrastColor={colors.tints.blues.b400}
            percentage={scorePercentage}
            radius={60}
          >
            <LightLabel>
              {t('innovatorDirectory:donutChart:advisor')}
            </LightLabel>
            <BoldLabel>
              {submission?.hasBeenFastPassed ||
              !submission?.submissionAverage?.wass
                ? t('innovatorDirectory:donutChart:noValue')
                : submission?.submissionAverage?.wass.toFixed(2)}
            </BoldLabel>
          </DonutChart>
        </DonutContainer>
      </Flex>
      <SubmissionSummary>
        <LightLabel>{t('innovatorDirectory:lastStep')}</LightLabel>
        <BoldLabel>
          {t(`callsForSubmission:step:name:${submission?.step}`)}
        </BoldLabel>
      </SubmissionSummary>
    </Container>
  )
}
export default SubmissionStatus
