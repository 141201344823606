import React, { useEffect, useState } from 'react'

import { getCsvData } from '../constants'
import { Flex } from '../../../FlexBox'
import Link from '../../../Link'
import useTranslation from '../../../../hooks/useTranslation'
import { Platform } from 'react-native'
import Sentry from '../../../../utils/sentry'
import Button from '../../../../ui-library/Button'
import useToast from '../../../../hooks/useToast'
import { useTheme } from 'styled-components/native'
import ModalDialog from './ModalDialog'
import { useLazyQuery } from '../../../../hooks/useQuery'

const CsvDownloadButton = ({
  config,
  data,
  currentUserId,
  isDisabled,
  fetchData,
  selectAll,
  selectCurrent
}) => {
  const { t } = useTranslation()
  const { setToastErrorMessage } = useToast()
  const [isProcessing, setIsProcessing] = useState(false)
  const [link, setLink] = useState<any>(null)
  const { colors, sizes } = useTheme()
  const { name, csvTypesMap = [] } = config
  const isCohortTable = name === 'cohort-selection-table'
  const [buttonColors, setButtonColors] = useState({
    background: '#FFFFFF',
    text: '#D9D9D9'
  })

  const URL = window.URL || window.webkitURL

  const cfsName = data ? data[0]?.callForSubmission?.name || '' : ''

  const [fetchQuery] = useLazyQuery(fetchData?.gqlQuery)

  const downloadFile = (url, type = '') => {
    const fileName = `Innovation-${cfsName}-${config?.name}-${type}.csv`
    const aElement = document.createElement('a')
    document.body.appendChild(aElement)
    aElement.href = url
    aElement.download = fileName
    aElement.click()
    URL.revokeObjectURL(url)
  }

  const downloadCsvTypes = async types => {
    const csvTypes = Object.entries(types)
      .filter(type => type[1])
      .map(type => type[0])
    setIsProcessing(true)
    await Promise.all(
      csvTypes.map(async type => {
        try {
          const csvType = csvTypesMap.filter(
            csvTypeMap => csvTypeMap.name === type
          )[0]
          const url = buildURI(
            {
              ...config,
              ...(csvType.customCsv
                ? {
                    getCustomCsvColumns: csvType?.getCustomCsvColumns,
                    getCustomCsvData: csvType?.getCustomCsvData,
                    getCustomCsvBody: csvType?.getCustomCsvBody
                  }
                : {}),
              customCsv: csvType.customCsv,
              excludedColumns: csvType?.excludedColumns
            },
            data
          )
          if (url) {
            downloadFile(url, type)
          }
        } catch (error) {
          Sentry.captureException(
            new Error(
              `Error while creating CSV Batch for type: ${type}, CFS: ${cfsName}`
            )
          )
        }
      })
    )
    setIsProcessing(false)
    setOpenDialog(false)
  }

  const [openDialog, setOpenDialog] = useState(false)

  useEffect(() => {
    if (!isDisabled) {
      setButtonColors({
        background: colors.background,
        text: colors.darkIcon
      })
    }
  }, [isDisabled])

  const buildURI = (config, data) => {
    try {
      const csv = getCsvData({ config, data, t, currentUserId })
      const type = 'text/csv'

      const blob = new Blob(['', csv], { type })
      const dataURI = `data:${type};charset=utf-8,''}${csv}`

      return typeof URL.createObjectURL === 'undefined'
        ? dataURI
        : URL.createObjectURL(blob)
    } catch (e) {
      setToastErrorMessage(t('error:csvError'))
      setIsProcessing(false)
    }
  }

  if (Platform.OS !== 'web') {
    return null
  }

  const fetchAllData = async () => {
    if (fetchData?.variables && fetchData?.totalElements) {
      if (fetchData.totalElements >= 250)
        throw t('error:selection:fetchDataSelectionLimit')

      let newVariables = { ...fetchData.variables }
      newVariables['size'] = fetchData.totalElements

      const allCompanies = await fetchQuery({ variables: newVariables })

      const newData = allCompanies?.data?.searchInnovationEngine?.hits
      if (newData?.length) {
        const url = buildURI(config, newData)
        if (url) downloadFile(url, 'all')
      } else throw t('error:selection:fetchData')
    }
  }

  const onSubmit = async () => {
    setLink(null)
    setIsProcessing(true)
    try {
      if (selectAll) {
        await fetchAllData()
      } else if (selectCurrent || data?.length) {
        setLink(buildURI(config, data))
      }
    } catch (error) {
      console.log({ error })
      setToastErrorMessage(error ?? t('error:csvError'))
    } finally {
      setTimeout(() => {
        setIsProcessing(false)
      }, 500)
    }
  }

  return (
    <Link
      to={link}
      title={t('common:table:exportCSV')}
      target="_self"
      download={`Innovation-${cfsName}-${config?.name}.csv`}
      style={{
        textDecoration: 'none'
      }}
    >
      <Flex flexDirection="row" alignItems="center">
        <Button
          disabled={isDisabled || isProcessing}
          title={t('common:table:exportCSV')}
          onPress={() => {
            if (isCohortTable) {
              setOpenDialog(!openDialog)
            } else {
              onSubmit()
            }
          }}
          buttonStyle={{
            width: sizes[6] + sizes[4],
            paddingVertical: 10,
            backgroundColor: buttonColors.background
          }}
          titleStyle={{
            color: buttonColors.text
          }}
          isProcessing={isProcessing}
        />
      </Flex>
      <ModalDialog
        title={t('common:table:exportCSV')}
        isOpen={openDialog}
        onClose={() => setOpenDialog(false)}
        handleSubmit={downloadCsvTypes}
        csvTypesMap={csvTypesMap}
        isProcessing={isProcessing}
      />
    </Link>
  )
}

export default CsvDownloadButton
