import { merge } from 'ramda'
import { useMemo } from 'react'
import useQuery from '../../../hooks/useQuery'
import { EngineEnum } from '../../../screens/CallForSubmission/constants'
import { mergeFilters } from '../utils'

import { useRecoilCallback } from 'recoil'
import { setSearchQueryByName } from '../../../recoil/searchQueryAtom'

// Queries
import { getSearchInnovationEngineQuery } from '../queries/getSearchInnovationEngineQuery'

const DEFAULT_CONDITIONS = {
  withSubmissionVotes: false
}

const DEFAULT_QUERY_NAME = 'searchInnovationEngine'

const useSearchQuery = ({
  enableFilters,
  pageSize,
  sortBy,
  sortDir,
  queryFilters,
  searchFilters,
  filters = {},
  engine,
  queryConditions = {},
  queryDynamicName = DEFAULT_QUERY_NAME,
  isFocused = false,
  currentCFSStep,
  getQuestionAndAnswers = false
}) => {
  const normalizedQueryName = queryDynamicName.split('-').join('_')
  const filterIds = Object.keys(queryFilters)
  const skip =
    !enableFilters ||
    !engine ||
    !sortBy ||
    (engine === EngineEnum.InnovationSubmission && !filterIds.length)
  const query = ''.concat(
    ...Object.values(searchFilters)
      .filter(val => val)
      .join(' OR ')
  )
  const mergedFilters = mergeFilters(
    {
      all: [
        ...filterIds.map(filterId => ({ [filterId]: queryFilters[filterId] }))
      ]
    },
    filters
  )

  const variables = {
    size: pageSize,
    sorts: query && query.length ? null : [{ sortBy, sortDir }],
    query,
    filters: mergedFilters,
    engine,
    ...merge(DEFAULT_CONDITIONS, queryConditions)
  }

  const setQueryByName = useRecoilCallback(setSearchQueryByName)
  const gqlQuery = useMemo(() => {
    return getSearchInnovationEngineQuery(
      normalizedQueryName,
      currentCFSStep,
      getQuestionAndAnswers
    )
  }, [])

  const { data, loading, refetch, fetchMore } = useQuery(gqlQuery, {
    variables,
    skip: skip || !isFocused
  })

  if (
    !skip &&
    normalizedQueryName !== DEFAULT_QUERY_NAME &&
    !loading &&
    isFocused
  ) {
    setQueryByName(normalizedQueryName, {
      variables,
      data,
      queryName: normalizedQueryName
    })
  }

  return {
    data,
    loading,
    refetch,
    fetchMore,
    fetchData: {
      gqlQuery,
      variables
    }
  }
}

export default useSearchQuery
