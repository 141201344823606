import React, { useEffect } from 'react'
import styled from 'styled-components/native'
import {
  Container,
  Title
} from '../CallForSubmission/components/SharedComponents'
import {
  ScreenContainerWithMenuHeader,
  StyledViewContainer
} from '../../components/layout/ScreenContainer'
import useIsSmallScreen from '../../hooks/useIsSmallScreen'

import useTranslation from '../../hooks/useTranslation'
import { DynamicTable, useTable } from '../../components/Table'

import { getInnovatorDirectoryTableConfig } from './constants'
import { EngineEnum } from '../CallForSubmission/constants'
import { TableContainer } from '../../components/StaticForm'
import { LoadingIndicator } from '../Curation/SubmissionDetails/components/SharedComponents'
import useLinkToScreen from '../../hooks/useLinkToScreen'
import useInnovationAccess from '../../hooks/useInnovationAccess'

export const ViewContainer = styled(StyledViewContainer)`
  height: 100%;
  overflow: hidden;
`

const InnovatorDirectoryList = () => {
  const { t } = useTranslation()
  const isSmallScreen = useIsSmallScreen()
  const linkToScreen = useLinkToScreen()
  const { directory: directoryAccess, accessReady } = useInnovationAccess()

  const tableProps = useTable({
    // @ts-ignore
    config: getInnovatorDirectoryTableConfig({
      engine: EngineEnum.InnovationInnovatorDirectoryEngine
    })
  })

  // JC: Since we're using the same routing for all roles, when an innovator
  // deep links to /innovator-directory we redirect them to their own profile
  useEffect(() => {
    if (!directoryAccess && accessReady) {
      linkToScreen('InnovatorDirectoryProfile')
    }
  }, [accessReady, directoryAccess])

  if (!directoryAccess) {
    return <LoadingIndicator />
  }

  return (
    <ScreenContainerWithMenuHeader screenCategory="InnovatorDirectory">
      <ViewContainer>
        <Title text={t('innovatorDirectory:title')} />
        <Container isSmallScreen={isSmallScreen}>
          <TableContainer height="100%">
            <DynamicTable
              {...tableProps}
              emptyMessage={t('innovatorDirectory:table:noInnovators')}
            />
          </TableContainer>
        </Container>
      </ViewContainer>
    </ScreenContainerWithMenuHeader>
  )
}

export default InnovatorDirectoryList
