import {
  CallForSubmissionStepEnum,
  DateTime,
  StepDateRange
} from '../../../types'
import * as Yup from 'yup'
import DateService from '../../../services/dateService'
import { Question } from '../../../types/form'
import {
  FAVORITES_DEFAULT_LIMIT,
  SHORTLISTED_DEFAULT_LIMIT
} from '../constants'

const { getDate } = DateService

interface StepDateRangeFormField {
  id?: string
  curationStep: CallForSubmissionStepEnum
  openAt: DateTime
  closeAt: DateTime
}

export interface Steps {
  submissionStep: StepDateRangeFormField
  preCurateStep: StepDateRangeFormField
  advisingStep: StepDateRangeFormField
  cohortStep: StepDateRangeFormField
  votingStep: StepDateRangeFormField
  finalSelectionsStep: StepDateRangeFormField
  selectionStep: StepDateRangeFormField
  eventStep: StepDateRangeFormField
}

const getByCurationStep = (targetStep: CallForSubmissionStepEnum) => ({
  curationStep
}) => curationStep === targetStep

export const calculateSteps = (stepDateRanges: StepDateRange[]) => {
  const submissionStep = stepDateRanges.find(
    getByCurationStep(CallForSubmissionStepEnum.submission)
  )
  const preCurateStep = stepDateRanges.find(
    getByCurationStep(CallForSubmissionStepEnum.preCurate)
  )
  const advisingStep = stepDateRanges.find(
    getByCurationStep(CallForSubmissionStepEnum.advising)
  )
  const cohortStep = stepDateRanges.find(
    getByCurationStep(CallForSubmissionStepEnum.cohort)
  )
  const votingStep = stepDateRanges.find(
    getByCurationStep(CallForSubmissionStepEnum.voting)
  )
  const finalSelectionsStep = stepDateRanges.find(
    getByCurationStep(CallForSubmissionStepEnum.finalSelections)
  )
  const selectionStep = stepDateRanges.find(
    getByCurationStep(CallForSubmissionStepEnum.selection)
  )
  const eventStep = stepDateRanges.find(
    getByCurationStep(CallForSubmissionStepEnum.event)
  )

  return {
    submissionStep,
    preCurateStep,
    advisingStep,
    cohortStep,
    votingStep,
    finalSelectionsStep,
    selectionStep,
    eventStep
  }
}

export const getGeneralSettingsValidationSchema = t => {
  const StepDateRangeSchema = Yup.object().shape({
    id: Yup.string(),
    curationStep: Yup.string().required('Curation Step required'),
    openAt: Yup.string().required(t('validation:error:required')),
    closeAt: Yup.string().required(t('validation:error:required'))
  })

  return Yup.object().shape({
    name: Yup.string().required(),
    shortlistLimit: Yup.number().required('Shortlist limit required'),
    favoritesLimit: Yup.number().required('Favorites limit required'),
    stepDateRanges: Yup.array().of(StepDateRangeSchema).required()
  })
}

export const generateGeneralSettingsInitialValues = (
  callForSubmission,
  steps,
  academyEligible,
  shortlistLimit,
  favoritesLimit
) => {
  return {
    name: callForSubmission.name,
    academyElegible: academyEligible,
    shortlistLimit: shortlistLimit ?? SHORTLISTED_DEFAULT_LIMIT,
    favoritesLimit: favoritesLimit ?? FAVORITES_DEFAULT_LIMIT,
    stepDateRanges: [
      {
        id: steps.submissionStep?.id,
        curationStep: CallForSubmissionStepEnum.submission,
        openAt: steps.submissionStep
          ? getDate(steps.submissionStep?.openAt)
          : undefined,
        closeAt: steps.submissionStep
          ? getDate(steps.submissionStep?.closeAt)
          : undefined
      },
      {
        id: steps.advisingStep?.id,
        curationStep: CallForSubmissionStepEnum.advising,
        openAt: steps.advisingStep
          ? getDate(steps.advisingStep?.openAt)
          : undefined,
        closeAt: steps.advisingStep
          ? getDate(steps.advisingStep?.closeAt)
          : undefined
      },
      {
        id: steps.votingStep?.id,
        curationStep: CallForSubmissionStepEnum.voting,
        openAt: steps.votingStep
          ? getDate(steps.votingStep?.openAt)
          : undefined,
        closeAt: steps.votingStep
          ? getDate(steps.votingStep?.closeAt)
          : undefined
      },
      {
        id: steps.selectionStep?.id,
        curationStep: CallForSubmissionStepEnum.selection,
        openAt: steps.selectionStep
          ? getDate(steps.selectionStep?.openAt)
          : undefined,
        closeAt: steps.selectionStep
          ? getDate(steps.selectionStep?.closeAt)
          : undefined
      },
      {
        id: steps.eventStep?.id,
        curationStep: CallForSubmissionStepEnum.event,
        openAt: steps.eventStep ? getDate(steps.eventStep?.openAt) : undefined,
        closeAt: steps.eventStep ? getDate(steps.eventStep?.closeAt) : undefined
      }
    ]
  }
}

// Advisor Settings Utils
export const getAdvisorSettingsValidationSchema = () => {
  const QuestionSchema = Yup.object().shape({
    id: Yup.string(),
    weight: Yup.number()
      .max(100, 'Weight can not be greater than 100')
      .min(0)
      .notRequired()
  })
  return Yup.object().shape({
    advisorMaxFav: Yup.number().notRequired(),
    questionsWeights: Yup.array().of(QuestionSchema).notRequired()
  })
}

export const generateAdvisorSettingsInitialValues = (
  reviewQuestions: Question[],
  advisorMaxFav
) => {
  return {
    advisorMaxFav,
    questionsWeights: reviewQuestions?.map(q => ({
      id: q.id,
      questionText: q.questionText,
      weight: `${q.weight}`
    }))
  }
}
