import React, { useEffect, useMemo, useState } from 'react'
import * as Yup from 'yup'

import { useTheme } from 'styled-components/native'
import useTranslation from '../../../../../../../hooks/useTranslation'

import { Switch, View } from 'react-native'
import { Text } from '../../../../../../../components/common/Text'
import { Footer, SaveButton } from '../../../../../constants'
import {
  FormBlocksModalProps,
  FormField,
  QuestionGroupEntityMapValues
} from '../../../../types'
import { FormikProvider, useFormik } from 'formik'
import { isEmpty } from 'ramda'
import useToast from '../../../../../../../hooks/useToast'
import useGetQuestionGroupEntityMaps from '../../../Forms/hooks/useGetQuestionGroupEntityMaps'
import {
  EntityTypeEnum,
  JSONStyles,
  QuestionGroupConfigData
} from '../../../../../../../components/DynamicForm/types'
import InputField from '../../../InputField'
import { JSONValidation } from '../../../../../utils'
import useUpsertQuestionGroupEntityMapAndEntity from '../../../Forms/hooks/useUpsertQuestionGroupEntityMap'
import Dropdown, {
  DropdownOption
} from '../../../../../../../components/common/Dropdown'
import SwitchField from '../../../SwitchField'

const questionFields: FormField[] = [
  {
    field: 'sequence',
    Component: InputField,
    isParent: false
  },
  {
    field: 'dynamic',
    Component: SwitchField,
    isParent: false
  },
  {
    field: 'style',
    Component: InputField,
    isJSON: true,
    isParent: false
  },
  {
    field: 'configData',
    Component: InputField,
    isJSON: true,
    isParent: false
  }
]

// Yup validation
const getValidationSchema = () =>
  Yup.object().shape({
    id: Yup.string().nullable(),
    sequence: Yup.number().integer().default(0).required(),
    dynamic: Yup.boolean().default(false),
    style: JSONValidation,
    configData: JSONValidation
  })

const Blocks = (props: FormBlocksModalProps) => {
  const {
    form,
    isEdit,
    question: questionGroupEntityMap,
    questions,
    onClose
  } = props
  const { colors } = useTheme()
  const { t } = useTranslation()
  const [cloneFromParent, setCloneFromParent] = useState<boolean>(false)
  const { setToastMessage, setToastErrorMessage } = useToast()
  const [parentQuestion, setParentQuestion] = useState<
    QuestionGroupEntityMapValues | undefined
  >()

  const [data, setData] = useState<QuestionGroupEntityMapValues[]>([])

  const {
    loading,
    questionGroupEntityMaps
  } = useGetQuestionGroupEntityMaps(
    true,
    undefined,
    undefined,
    EntityTypeEnum.QuestionGroup,
    { skip: !cloneFromParent }
  )

  useEffect(() => {
    if (!loading && questionGroupEntityMaps) {
      setData(questionGroupEntityMaps)
    }
  }, [questionGroupEntityMaps, loading])

  const questionsOptions: DropdownOption[] = useMemo(
    () =>
      data.map(question => ({
        label: JSON.stringify(question?.entity?.configData) ?? '',
        value: question.id
      })),
    [data]
  )

  const [
    upsertQuestionGroupEntityMapAndEntity,
    isLoading
  ] = useUpsertQuestionGroupEntityMapAndEntity({
    onError: e => console.error(e),
    onCompleted: ({ upsertQuestionGroupEntityMapAndEntity }) => {
      if (
        upsertQuestionGroupEntityMapAndEntity &&
        !isEmpty(upsertQuestionGroupEntityMapAndEntity)
      ) {
        setToastMessage(
          t('platformManagement:forms:menuItems:forms:successSaved')
        )
      } else {
        setToastErrorMessage(
          t('platformManagement:forms:menuItems:forms:errorSave')
        )
      }
    },
    errorMessage: t('platformManagement:questionGroups:errorSave')
  })

  const currentQuestion = parentQuestion || questionGroupEntityMap

  const questionKeys = currentQuestion
    ? [
        ...Object.keys(currentQuestion),
        ...Object.keys(currentQuestion.entity ?? {})
      ]
    : undefined

  let initialValues: any = {
    sequence: questions?.length ?? 0,
    dynamic: false,
    configData: '{}',
    style: '{}'
  }

  if (currentQuestion && questionKeys) {
    for (
      let indexQuestionKey = 0;
      indexQuestionKey < questionKeys.length;
      indexQuestionKey++
    ) {
      const questionKey = questionKeys[indexQuestionKey]
      if (
        ['id', ...questionFields.map(qField => qField.field)].includes(
          questionKey
        )
      ) {
        const currentValue =
          currentQuestion[questionKey] ?? currentQuestion?.entity?.[questionKey]

        if (typeof currentValue === 'object' && currentValue != null) {
          initialValues[questionKey] = JSON.stringify(currentValue)
        } else {
          initialValues[questionKey] = currentValue ?? undefined
        }
      }
    }
  }

  // Formik
  const formik = useFormik<{
    id: string
    sequence: string
    dynamic: boolean | string
    style: JSONStyles
    configData: QuestionGroupConfigData
  }>({
    initialValues,
    validationSchema: getValidationSchema(),
    enableReinitialize: true,
    onSubmit: async values => {
      upsertQuestionGroupEntityMapAndEntity({
        variables: {
          id:
            questionGroupEntityMap && questionGroupEntityMap.id
              ? values.id
              : undefined,
          entityType: EntityTypeEnum.QuestionGroup,
          sequence: parseInt(values.sequence),
          dynamic:
            typeof values.dynamic === 'string'
              ? values.dynamic === 'true'
              : values.dynamic,
          style: values.style,
          configData: values.configData,
          parentId: parentQuestion?.entityId,
          questionGroupId: form.questionGroupId
        },
        refetchQueries: ['getQuestionGroupEntityMaps']
      })
    }
  })

  const validateAndSubmit = async () => {
    try {
      await formik.validateForm().then(() => formik.submitForm())
      setParentQuestion(undefined)
      onClose()
    } catch (error) {
      console.error(error)
    }
  }

  const isNew = useMemo(() => !questionGroupEntityMap?.id, [
    questionGroupEntityMap?.id
  ])

  const handleCloneFromParent = (value: boolean) => {
    if (!value) {
      setParentQuestion(undefined)
    }
    formik.resetForm({
      values: {
        id: '',
        sequence: '0',
        dynamic: false,
        style: {},
        configData: {}
      }
    })
    setCloneFromParent(value)
  }

  const enableSubmit = useMemo(() => {
    return formik.isValid
  }, [formik])

  return (
    <View
      style={{
        marginTop: 5,
        width: '100%',
        height: 400,
        flexDirection: 'row'
      }}
    >
      <View
        style={{
          flex: 1,
          marginTop: 10
        }}
      >
        {isNew ? (
          <View
            style={{
              paddingVertical: 10,
              width: '100%'
            }}
          >
            <Text styles={{ marginBottom: 5 }}>
              {t(
                'platformManagement:forms:menuItems:formBlocks:cloneExistingForm'
              )}
            </Text>
            <Switch
              value={cloneFromParent}
              onValueChange={handleCloneFromParent}
              style={{
                // margin: space[3],
                transform: [{ scaleX: 0.8 }, { scaleY: 0.8 }]
              }}
              trackColor={{
                true: colors.primaryPalette.accent,
                false: colors.border
              }}
            />
            {cloneFromParent ? (
              <Dropdown
                label={t(
                  'platformManagement:forms:menuItems:formBlocks:fields:parentFormBlock'
                )}
                isClearable
                isFilter
                isDisabled={isLoading}
                closeMenuOnSelect
                style={{
                  minHeight: 0,
                  marginBottom: 0
                }}
                value={parentQuestion?.id ?? null}
                containerStyle={{ width: 350, height: 50, marginRight: 10 }}
                isLoading={isLoading}
                options={questionsOptions}
                controlStyle={{ minHeight: 0 }}
                menuPortalTarget={document?.body}
                onSelect={value => {
                  const currentQuestion = questionGroupEntityMaps.find(
                    question => question.id === value
                  )
                  if (currentQuestion) {
                    setParentQuestion(currentQuestion)
                  }
                }}
                name={'parentFormSelector'}
                placeholder={t(
                  'platformManagement:forms:menuItems:forms:selectorPlaceholder'
                )}
              />
            ) : null}
          </View>
        ) : null}
        <FormikProvider value={formik}>
          {questionFields
            .filter(formField => (isNew ? !formField.hideOnNew : true))
            .map(formField => {
              const { Component, field, options, isParent } = formField
              const componentProps = {
                type: 'forms',
                key: field,
                fieldName: field,
                isReadOnly:
                  (isEdit && isParent) ||
                  (isNew && cloneFromParent && !parentQuestion?.id),
                options
              }

              return <Component {...componentProps} />
            })}

          <Footer>
            <SaveButton
              disabled={!enableSubmit}
              onPress={validateAndSubmit}
              isProcessing={isLoading}
              title={t(`common:buttons:${isEdit ? 'update' : 'save'}`)}
            />
          </Footer>
        </FormikProvider>
      </View>
    </View>
  )
}

export default Blocks
