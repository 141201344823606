import { clone, merge } from 'ramda'
import useQuery from '../../../hooks/useQuery'
import { EngineEnum } from '../../../screens/CallForSubmission/constants'

import { setSearchQueryByName } from '../../../recoil/searchQueryAtom'
import { useRecoilCallback } from 'recoil'
import { mergeFilters } from '../utils'
import { useEffect, useMemo } from 'react'

// Queries
import { getSearchInnovationEngineQuery } from '../queries/getSearchInnovationEngineQuery'

const DEFAULT_CONDITIONS = {
  withSubmissionVotes: false
}

const DEFAULT_QUERY_NAME = 'searchInnovationEngine'

const useSearchQuery = ({
  enableFilters,
  pageSize,
  sortBy,
  sortDir,
  queryFilters,
  searchFilters,
  filters = {},
  engine,
  queryConditions = {},
  queryDynamicName = DEFAULT_QUERY_NAME,
  isFocused = false,
  isLoading,
  customQueryFields,
  skipCache = false,
  skipFetchData = false,
  fetchingDataOnFocus = true,
  fetchPolicy
}) => {
  const normalizedQueryName = queryDynamicName.split('-').join('_')
  const filterIds = Object.keys(queryFilters)
  const skip =
    isLoading ||
    !enableFilters ||
    !engine ||
    !sortBy ||
    (engine === EngineEnum.InnovationSubmission && !filterIds.length) ||
    skipFetchData ||
    (fetchingDataOnFocus && !isFocused)
  const query = ''.concat(
    ...Object.values(searchFilters)
      .filter(val => val)
      .join(' OR ')
  )
  const mergedFilters = mergeFilters(
    {
      all: [
        ...filterIds.map(filterId => ({ [filterId]: queryFilters[filterId] }))
      ]
    },
    filters
  )
  const variables = {
    size: pageSize,
    sorts: query && query.length ? null : [{ sortBy, sortDir }],
    query,
    filters: mergedFilters,
    engine,
    ...merge(DEFAULT_CONDITIONS, queryConditions)
  }
  const setQueryByName = useRecoilCallback(setSearchQueryByName)

  const searchQuery = useMemo(() => {
    return getSearchInnovationEngineQuery(
      normalizedQueryName,
      engine,
      customQueryFields
    )
  }, [normalizedQueryName, engine, customQueryFields])

  const { data, loading, refetch, fetchMore } = useQuery(searchQuery, {
    variables,
    skip: skip,
    fetchPolicy: skipCache ? 'network-only' : fetchPolicy,
    onError: error => console.log(error)
  })

  useEffect(() => {
    if (
      !skip &&
      normalizedQueryName !== DEFAULT_QUERY_NAME &&
      !loading &&
      isFocused
    ) {
      setQueryByName(normalizedQueryName, {
        variables,
        data: clone(data),
        query: clone(searchQuery),
        queryName: normalizedQueryName
      })
    }
  }, [
    skip,
    normalizedQueryName,
    DEFAULT_QUERY_NAME,
    loading,
    isFocused,
    variables,
    data
  ])

  return {
    data,
    loading,
    refetch,
    fetchMore
  }
}

export default useSearchQuery
