import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components/native'
import {
  Container,
  Title
} from '../CallForSubmission/components/SharedComponents'

import {
  ScreenContainerWithMenuHeader,
  StyledScrollViewContainer
} from '../../components/layout/ScreenContainer'
import useIsSmallScreen from '../../hooks/useIsSmallScreen'

import useLinkToScreen from '../../hooks/useLinkToScreen'
import { useRoute } from '@react-navigation/native'
import useCompanyQuery from '../../hooks/useCompanyQuery'
import useCurrentUser from '../../hooks/useCurrentUser'
import useCreteAlumniMembershipMutation from '../../hooks/useCreateAlumniMembershipMutation'
import useDeleteAlumniMembershipMutation from '../../hooks/useDeleteAlumniMembershipMutation'
import { ActivityIndicator, Switch, View } from 'react-native'
import useTranslation from '../../hooks/useTranslation'
import CompanyProfile from './components/CompanyProfile'
import { Text } from '../../components/common/Text'
import Dialog from '../../components/common/Dialog'
import { RoleEnum } from '../../types'

const InnovatorDirectoryDetails = () => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const isSmallScreen = useIsSmallScreen()
  const linkToScreen = useLinkToScreen()
  const route: any = useRoute()
  const companyId = route?.params?.companyId
  const [openDialog, setOpenDialog] = useState(false)
  const { currentUser } = useCurrentUser()
  const { company, loading } = useCompanyQuery(companyId)
  const [createAlumniMembership] = useCreteAlumniMembershipMutation()
  const [deleteAlumniMembership] = useDeleteAlumniMembershipMutation()
  const [alumniMembershipId, setAlumniMembershipId] = useState('')

  useEffect(() => {
    if (company) {
      setAlumniMembershipId(company?.alumniMembership?.id)
    }
  }, [company])

  if (!companyId || loading || !company) {
    return <ActivityIndicator size="large" />
  }

  const onToggleAlumniMembership = async () => {
    setOpenDialog(false)
    if (alumniMembershipId) {
      await deleteAlumniMembership({
        variables: {
          id: alumniMembershipId
        }
      })
      setAlumniMembershipId('')
    } else {
      const {
        data: { createAlumniMembership: membership }
      } = await createAlumniMembership({
        variables: {
          companyId: company.id
        }
      })
      setAlumniMembershipId(membership.id)
    }
  }

  const isAlumni = !!alumniMembershipId
  const isAdmin = currentUser?.roles.includes(RoleEnum.Admin)

  return (
    <ScreenContainerWithMenuHeader screenCategory="InnovatorDirectory">
      <StyledScrollViewContainer>
        <Title
          text={`${t('innovatorDirectory:details:title')} - ${company.name}`}
          withBackArrow
          onBackArrow={() => {
            linkToScreen('InnovatorDirectoryProfile', {
              companyId: companyId
            })
          }}
        >
          {isAdmin && (
            <View style={{ marginLeft: 'auto' }}>
              <Text>{t('company:isAlumni')}</Text>
              <Switch
                disabled={false}
                value={isAlumni}
                onValueChange={() => {
                  setOpenDialog(true)
                }}
                style={{
                  alignSelf: 'center',
                  marginTop: '10px'
                }}
                trackColor={{
                  true: colors.primaryPalette.accent,
                  false: colors.border
                }}
              />
            </View>
          )}
        </Title>

        <Dialog
          title={
            alumniMembershipId
              ? t('company:alumniDeleteConfirmTitle')
              : t('company:alumniMakeConfirmTitle')
          }
          description={t('company:alumniModifyWarning')}
          isOpen={openDialog}
          onClose={() => {
            setOpenDialog(false)
          }}
          actions={[
            {
              title: t('common:dialogs:unsavedChanges:discardBtn'),
              type: 'outline',
              onPress: () => {
                setOpenDialog(false)
              }
            },
            {
              title: t('common:dialogs:unsavedChanges:saveBtn'),
              onPress: onToggleAlumniMembership
            }
          ]}
        />

        <Container testID={'companyInfoForm'} isSmallScreen={isSmallScreen}>
          <CompanyProfile
            company={company}
            containerStyles={{
              padding: 0
            }}
            refetchQueries={['directoryProfileSearchInnovationEngine']}
          />
        </Container>
      </StyledScrollViewContainer>
    </ScreenContainerWithMenuHeader>
  )
}

export default InnovatorDirectoryDetails
